aside.share {
    position: absolute;
    top: 0;
    right: 10*$base-unit;
    padding: 2*$base-unit;
    background-color: $black;
    border-radius: 0.5*$base-unit;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    &.fixed {
        position: fixed;
        top: 56px;
    }

    & .share-links {
        & a {
            display: block;
            max-height: 54px;
            padding: 1.875*$base-unit;
            font-size: 24px;
            color: $pink;
            cursor: pointer;
            transition: all 250ms ease-in-out;

            &:hover {
                color: $white;
            }
        }
    }
}

@media only screen and (min-width: 1281px) {
    .container {
        & aside.share {
            &.fixed {
                right: calc(50vw - 519px);
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .container {
        & aside.share {
            right: -44px;

            &.fixed {
                right: calc(50vw - 524px);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .container {
        & aside.share {
            padding: 0.5*$base-unit;
            right: -64px;

            &.fixed {
                right: calc(50vw - 464px);
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .container {
        & aside.share {
            position: relative;
            display: inline-block;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;

            &.fixed {
                position: relative;
                top: auto;
                right: auto;
                bottom: auto;
                left: auto;
            }

            & .share-links a {
                display: inline-block;
                max-height: 54px;
                padding: 15px;
                font-size: 24px;
            }
        }
    }
}
