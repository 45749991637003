/* sass-lint:disable-all*/

/* ! Reset - Box sizing */

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

/* sass-lint:enable-all*/
