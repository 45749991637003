.header-visual {
    position: relative;
    display: block;
    width: 100vw;
    height: auto;
    min-height: 714px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 1px solid $darkergray;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
    }

    &.blank {
        height: 120px;
        min-height: 0;
        background-color: $pink;
        border: none;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: none;
        }
    }

    &.empty {
        height: 120px;
        min-height: 0;
        border: none;
        min-height: 330px;

        & .container {
            min-height: 330px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: none;
        }
    }

    & .container {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        min-height: 714px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: 1px;
        }

        & a {
            display: block;
            color: $white;
            text-decoration: none;
        }

        & h1 {
            margin-left: 16px;

            & span {
                display: inline;
                font-family: $heading;
                font-size: 7.5*$base-unit;
                font-style: $normal;
                font-weight: $medium;
                line-height: 10.875*$base-unit;
                color: $white;
                background: $pink;
                /*background-image: linear-gradient(to bottom, transparent 0, transparent 8px, $pink 8px, $pink 68px, transparent 68px);*/
                box-shadow: 16px 0 0 0 $pink, -16px 0 0 0 $pink;
            }
        }

        & .header-author {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: flex-start;
            justify-content: left;
            width: 100%;
            margin: 0;
            
            & .photo-2-col .photo:last-child{
                left:156px;
            }
            & .photo-2-col .photo:first-child{
                left:0!important;
            }

            & .photo,
            & h3,
            & p {
                position: relative;
            }

            & .photo {
                position: absolute;
                bottom: 0;
                display: inline-block;
                width: 152px;
                height: 152px;
                margin: 0 24px 0 0;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
            }

            & .info {
                width: calc(100%);
                padding: 8px 0 0 181px;
                background-color: $black;

                & h3 {
                    margin: 0;
                    font-family: $heading;
                    font-size: 7.5*$base-unit;
                    font-style: $normal;
                    font-weight: $bold;
                    line-height: 9.625*$base-unit;
                    color: #FFFFFF;
                }

                & p {
                    margin: 0 0 -10px;
                    font-family: $text;
                    font-size: 3*$base-unit;
                    font-style: $italic;
                    font-weight: $medium;
                    line-height: 3.75*$base-unit;
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media only screen and (min-width: 1281px) {
    .header-visual {
        & .container {
            max-width: 786px;
        }

        &.home {
            & .container {
                max-width: 1198px;

                & h1 {
                    max-width: 60%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .header-visual {
        & .container {
            max-width: 786px;
        }

        &.home {
            & .container {
                max-width: 960px;
            }

            & h1 {
                max-width: 75%;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .header-visual {
        & .container {
            max-width: 786px;
        }

        &.home {
            & .container {
                max-width: 786px;
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .header-visual {
        & .container {
            max-width: 100%;

            & h1 {
                width: calc(100% - 32px);
                margin-left: 16px;

                & span {
                    font-size: 6*$base-unit;
                    line-height: 9*$base-unit;
                }
            }

            & .header-author {
                left: 16px;
                width: calc(100% - 32px);

                & .info {

                    & h3 {
                        font-size: 6*$base-unit;
                        line-height: 8*$base-unit;
                    }
                }

                & .photo {
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 512px) {
    .header-visual {
        & .container {
            max-width: 100%;

            & h1 {
                width: calc(100% - 32px);
                margin-left: 16px;

                & span {
                    font-size: 5*$base-unit;
                    line-height: 8*$base-unit;
                }
            }

            & .header-author {
                left: 16px;
                width: calc(100% - 32px);

                & .info {
                    width: 100%;
                    padding: 16px 0 8px 144px;

                    & h3 {
                        font-size: 5*$base-unit;
                        line-height: 7*$base-unit;
                    }
                }

                & .photo {
                    width: 128px;
                    height: 128px;
                }
            }
        }
    }
}


@media only screen and (max-width: 414px) {
    .header-visual {
        & .container {
            max-width: 100%;

            & h1 {
                width: calc(100% - 32px);
                margin-left: 16px;

                & span {
                    font-size: 4*$base-unit;
                    line-height: 7*$base-unit;
                }
            }

            & .header-author {

                & .info {
                    width: 100%;
                    padding: 8px 0 8px 102px;

                    & h3 {
                        font-size: 3*$base-unit;
                        line-height: 4*$base-unit;
                    }

                    & p {
                        font-size: 2*$base-unit;
                        line-height: 3*$base-unit;
                    }
                }

                & .photo {
                    width: 88px;
                    height: 88px;
                }
            }
        }
    }
}
