html,
body {
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
}

body {
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.container {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.adapt-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 715px;
}

@media only screen and (min-width: 1281px) {
    .container {
        max-width: 1198px;
    }
}

@media only screen and (max-width: 1280px) {
    .container {
        max-width: 960px;
    }
}

@media only screen and (max-width: 1024px) {
    .container {
        max-width: 800px;
    }
}

@media only screen and (max-width: 812px) {
    .container {
        max-width: 100vw;
        padding: 0 16px;
    }
}
