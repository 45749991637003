/* ! Settings - Font families */
@import url("https://use.typekit.net/sym4qfc.css");

$text: 'freight-text-pro', Arial, sans-serif;
$heading: 'freight-sans-pro', Arial, sans-serif;
$icon: 'Material Icons';

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/MaterialIcons-Regular.eot?') format('eot'),
         url('../fonts/MaterialIcons-Regular.woff2') format('woff2'),
         url('../fonts/MaterialIcons-Regular.woff') format('woff'),
         url('../fonts/MaterialIcons-Regular.ttf') format('truetype'),
         url('../fonts/MaterialIcons-Regular.svg#Material_Icons') format('svg')
}
.material-icons {
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 24px;
    font-style: normal;
    font-weight: var(--regular);
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: 'liga';
    -moz-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
}
