footer {
    width: 100vw;
    padding: 80px 0;
    background-color: $nearblack;

    & .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        & .back-to-top {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 54px;
            height: 54px;
            padding: 15px;
            font-size: 24px;
            align-items: center;
            justify-content: center;
            display: flex;
            text-decoration: none;
            color: $pink;
            border-radius: 4px;
            background-color: $middlegray;
        }

        & .col {
            
            & p {
                margin: 0 0 24px;
                font-family: $heading;
                font-size: 2.25*$base-unit;
                font-style: $normal;
                font-weight: $semibold;
                line-height: 2.875*$base-unit;
                color: $middlegray;
                text-decoration: none;
            }

            &.gasterra {
                width: 35%;

                & p {
                    & span:last-child {
                        font-weight: $medium;
                    }
                }
            }

            &.socials {
                width: 30%;

                & .social-links {
                    & .social {
                        display: inline-block;
                        width: 54px;
                        height: 54px;
                        padding: 15px;
                        margin: 0 16px 0 0;
                        font-size: 24px;
                        color: $pink;
                        background-color: $black;
                        border-radius: 50%;
                        transition: all 250ms ease-in-out;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }

            &.links {
                width: 35%;

                & ul {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    & li {
                        width: 46%;

                        & a {
                            margin: 0 0 24px;
                            font-family: $heading;
                            font-size: 2.25*$base-unit;
                            font-style: $normal;
                            font-weight: $semibold;
                            line-height: 2.875*$base-unit;
                            color: $pink;
                            text-align: left;
                            text-decoration: none;
                            transition: all 250ms ease-in-out;

                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1024px) {
    footer {
        .container {
            & .col {
                &.gasterra {
                    width: 40%;
                }

                &.socials {
                    width: 20%;
                }

                &.links {
                    width: 40%;
                }
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    footer {
        .container {
            display: block;

            & .col {
                margin-bottom: 32px;

                &.gasterra,
                &.socials,
                &.links {
                    display: block;
                    width: 100%;
                    text-align: center;
                    
                    & p {
                        text-align: center;
                    }

                    & img {
                        margin: 0 auto;
                    }
                }

                &.socials {
                    & .social-links {
                        & .social {
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }

                &.links {
                    & ul {
                        flex-direction: column;

                        & li {
                            width: 100%;
                            margin-bottom: 16px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
