.search-window {
    height: 300px;
    top: -300px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    background-color: #fff;
    z-index: 100;
    width: 100%;
    min-height: 0;
    max-width: none;
    left: 0;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    right: 0;
    position: absolute;
    transition: 300ms top ease;
    flex-direction: row;
    margin: 0;
    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
    }
}

.field-text {
    max-width: 90%;
    width: 700px;
    display: flex;
}
.search-window h2 {
    width: 100%;
    text-align: center;
    font-size: 44px;
    margin-top: 0;
    margin-bottom: 24px;
}
form.search-window .input:first-of-type {
    width: 100%;
    margin-top: 0;
    padding: 16px 24px;
}
.search-window.enabled {
    top: 0;
}

.search-box {
    .text-center {
        text-align: center;
    }
    a.button-link {
        width: auto;
        padding: 16px 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        color: $pink;
        text-decoration: none;
        cursor: pointer;
        background-color: $black;
        border-radius: 4px;
        transition: all 250ms ease-in-out;
        margin-left: 8px;
        margin-right: 8px;
        &:hover,
        &:active {
            box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
            transform: translate(1px, -1px);
        }
    }
    p.small {
        font-size: 16px;
            font-family: "freight-sans-pro",
            Arial,
            sans-serif;
        margin-bottom: 48px;
        color:#575757;
    }
    h1 {
        margin-bottom: 0.33em;
    }
    .results {
        a {
            color: #000;
            text-decoration: none;
        }
        a h2 {
            color: #FD6F7D;
            margin-bottom: 8px;
        }
        a p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 48px;
        }
    }
}