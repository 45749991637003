.carousel {
    position: relative;
    width: 100%;
    max-width: 786px;
    height: auto;
    min-height: 0;
    margin: 0 auto 56px auto;

    & h3 {
        margin: 0 0 56px;
        font-family: $heading;
        font-size: 4*$base-unit;
        line-height: 5.125*$base-unit;
        font-weight: $bold;
        font-style: $normal;
    }

    & .has-slider {
        position: relative;
        width: 100%;
        height: 513px;
    
        & .related-items {
            position: absolute;
            height: 513px;
            margin: 0;
            font-size: 0;
            line-height: 0;
            
            & .related-item {
                display: inline-block;
                width: 370px;
                height: 513px;
                padding: 0;
                margin: 0 45px 0 0;
                pointer-events: none;
                background-color: $white;
                border-radius: 4px;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
                transition: all 300ms ease-in-out;

                &.hover {
                    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3), 0 8px 24px 0 rgba(0, 0, 0, 0.15);
                }

                & .related-link {
                    text-decoration: none;
                    pointer-events: auto;

                    & .category {
                        position: absolute;
                        z-index: 2;
                        display: block;
                        width: 106px;
                        height: 46px;
                        padding: 0;
                        margin: 0 0 0 132px;
                        font-family: $heading;
                        font-size: 14px;
                        font-weight: $semibold;
                        line-height: 18px;
                        color: $white;
                        text-align: center;
                        background-color: $black;

                        & span {
                            display: block;
                            width: 1px;
                            height: 14px;
                            margin: 0 auto 6px;
                            background-color: $white;
                        }
                    }

                    & .related-item-image {
                        position: relative;
                        z-index: 1;
                        display: block;
                        width: 100%;
                        height: 222px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        border-radius: 4px 4px 0 0;

                        & .photo {
                            position: absolute;
                            z-index: 2;
                            bottom: 0;
                            left: 0;
                            width: 76px;
                            height: 76px;
                            background-position: center;
                            background-size: cover;
                        }
            
                        & .info {
                            position: absolute;
                            z-index: 1;
                            bottom: 0;
                            width: 100%;
                            height: 32px;
                            padding: 5px 0 4px 92px;
                            color: $white;
                            background-color: $black;
                            font-family: $heading;
                            font-size: 2.25*$base-unit;
                            line-height: 2.875*$base-unit;
                            font-weight: $semibold;
                            font-style: $normal;
            
                            & span {
                                margin-right: $base-unit;
                                color: $white;
                                font-family: $text;
                                font-size: 2*$base-unit;
                                line-height: 2.5*$base-unit;
                                font-weight: $medium;
                                font-style: $italic;
                            }
                        }
                    }
    
                    & .related-item-info {
                        width: 100%;
                        height: 241px;
                        padding: 24px 24px 0;
                        margin-bottom: 50px;
                        overflow: hidden;
                        text-overflow: ellipsis;
    
                        & h4 {
                            margin: 0 0 16px;
                            font-family: $heading;
                            font-size: 3.125*$base-unit;
                            font-style: $normal;
                            font-weight: $semibold;
                            line-height: 4*$base-unit;
                            color: $black;
                            text-decoration: none;
                        }
    
                        & p {
                            margin: 0;
                            font-family: $text;
                            font-size: 2.375*$base-unit;
                            font-style: $normal;
                            font-weight: $medium;
                            line-height: 3*$base-unit;
                            color: $darkgray;
                            text-decoration: none;
                        }
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    .carousel {
        & .has-slider {
            & .related-items {
                & .related-item {
                    width: calc(100vw - 32px);
                    margin: 0 32px 0 0;

                    & .category {
                        margin: 0 0 0 calc(50% - 66px);
                    }
                }
            }
        }
    }
}
