.author {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    margin: 86px auto 112px;

    & .photo,
    & h3,
    & p {
        position: relative;
    }

    & .photo {
        display: inline-block;
        width: 152px;
        height: 207px;
        margin: -32px 24px 42px 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: $base-unit;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    }

    & .info {
        width: calc(100% - 216px);
        padding: 16px 16px 56px 0;

        & h3 {
            margin: 0 0 16px;
            font-family: $heading;
            font-size: 5*$base-unit;
            font-style: $normal;
            font-weight: $bold;
            line-height: 6.5*$base-unit;
        }
    
        & p {
            margin: 0;
            font-family: $text;
            font-size: 3.25*$base-unit;
            font-style: $normal;
            font-weight: $medium;
            line-height: 4.125*$base-unit;
        }
    }
}

.author:before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(-50vw + 393px);
    z-index: 0;
    width: calc(50vw + 393px);
    height: 100%;
    background-color: $bluegray;
}

@media only screen and (min-width: 1281px) {
    .author {
        max-width: 786px;
    }
}

@media only screen and (max-width: 1280px) {
    .author {
        max-width: 786px;
    }
}

@media only screen and (max-width: 1024px) {
    .author {
        max-width: 786px;
    }
}

@media only screen and (max-width: 812px) {
    .author {
        max-width: 100%;
    }

    .author:before {
        left: -16px;
        width: calc(100vw - 16px);
    }
}

@media only screen and (max-width: 500px) {
    .author {
        display: block;

        & .photo {
            display: inline-block;
        }
        & .info {
            display: block;
            width: 100%;
        }
    }
}
