.podcast-pagetitle,
.podcast-player {
    width: 100%;
    max-width: 786px;
    margin: 0 auto 32px;

    & .podcast-title {
        margin: 0 auto 8px;
        font-family: "freight-text-pro",Arial,sans-serif;
        font-size: 20px;
        line-height: 27px;
        font-weight: 600;
    }
}

.podcast {
    position: relative;
    display: inline-block;
    width: calc(50% - 21px);
    padding: 24px;
    background-color: $middlegray;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    & .left {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 60%;

        & h2 {
            margin: 0;
            font-family: $heading;
            font-size: 6.25*$base-unit;
            font-style: $normal;
            font-weight: $semibold;
            line-height: 6.875*$base-unit;
            color: $white;
        }
    
        & p {
            margin: 13px 0 32px;
            font-family: $text;
            font-size: 2.375*$base-unit;
            font-style: $normal;
            font-weight: $medium;
            line-height: 3*$base-unit;
            color: $black;
        }

        & .button-link {
            display: inline-block;
            padding: 15px 16px 15px 24px;
            color: $pink;
            text-decoration: none;
            background-color: $white;
            border-radius: 4px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
            transition: all 250ms ease-in-out;

            & i {
                padding: 4px 5px;
                margin-left: 4px;
                font-size: 16px;
            }

            &:hover,
            &:active {
                box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
                transform: translate(1px, -1px);
            }
        }
    }

    & .right {
        position: absolute;
        top: 49px;
        right: 53px;
        z-index: 1;
    }
}

@media only screen and (max-width: 768px) {
    .podcast {
        width: 100%;
        margin: 0 0 32px;
    }
}
