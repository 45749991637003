form,
.contact-thanks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 640px;
    min-height: 600px;
    margin: 0 auto;
    font-family: "freight-sans-pro", Arial, sans-serif;

    & h1 {
        margin: 0;
    }

    & p {
        margin: 16px 0 0;
    }

    .input:first-of-type {
        margin-top: 32px;
    }

    & button.button-link {
        width: auto;
        padding: 16px 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        color: $pink;
        cursor: pointer;
        background-color: $black;
        border-radius: 4px;
        transition: all 250ms ease-in-out;

        &:hover,
        &:active {
            box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
            transform: translate(1px, -1px);
        }
    }

    & .input {
        position: relative;
        width: 100%;

        &.not-empty {
            & label {
                top: -6px;
                font-size: 11px;
                color: $black;
                transition: all 300ms ease;
            }
        }

        & label {
            position: absolute;
            top: 8px;
            left: 15px;
            font-size: 16px;
            color: $darkergray;
            pointer-events: none;
            transition: all 300ms ease;
        }

        & .required {
            & label {
                &:after {
                    &:first-child {
                        &:after {
                            content: '*';
                        }
                    }
                }
            }
        }

        & input,
        & textarea {
            width: 100%;
            padding: 8px 16px;
            margin-bottom: 24px;
            color: $nearblack;
            background-color: transparent;
            border: 0;
            border-bottom: 2px solid $darkgray;

            &:focus {
                outline: none;
            }
        }

        & .error {
            position: absolute;
            top: 35px;
            left: 15px;
            padding: 4px 0 0 2px;
            font-family: 'freight-sans-pro', Arial, sans-serif;
            font-size: 11px;
            color: $error;
            opacity: 0;
            transition: all 300ms ease;
        }

        &.invalid {
            & input,
            & textarea,
            & select {
                border-bottom: 2px solid $error;

                &:focus {
                    outline: none;
                    border-bottom: 2px solid $error;
                }
            }

            & .error {
                opacity: 1;
            }

            &.default-checkbox .input-checkbox input[type='checkbox'] + .checkbox:before {
                color: $error;
            }
        }
        
        &.default-checkbox {
            position: relative;
            display: block;
            margin-bottom: 32px;
            font-family: 'Conduit', sans-serif;
            font-size: 15px;
            font-weight: normal;
            color: $black;
            letter-spacing: 0.5px;

            & .error {
                top: auto;
                bottom: -12px;
                left: 30px;
                font-family: 'freight-sans-pro', Arial, sans-serif;
            }

            & .input-checkbox {
                position: absolute;
                display: inline-block;
                width: 32px;
                height: 32px;
                margin: -11px 8px 0 -18px;
                text-align: center;
                vertical-align: -9px;

                & input[type='checkbox'] {
                    position: absolute;
                    bottom: 7px;
                    left: 7px;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    visibility: hidden;
                    outline: none;
                    opacity: 0;

                    & + .checkbox:before {
                        position: absolute;
                        bottom: 8px;
                        left: 4px;
                        z-index: 1;
                        width: 18px;
                        height: 18px;
                        font-family: 'Material Icons';
                        font-size: 24px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        text-transform: none;
                        letter-spacing: normal;
                        word-wrap: normal;
                        white-space: nowrap;
                        vertical-align: -6px;
                        transition: all 0.2s ease;
                        direction: ltr;
                        /* Support for all WebKit browsers. */
                        -webkit-font-smoothing: antialiased;
                        /* Support for Safari and Chrome. */
                        text-rendering: optimizeLegibility;
                        /* Support for Firefox. */
                        -moz-osx-font-smoothing: grayscale;
                        /* Support for IE. */
                        font-feature-settings: 'liga';
                    }

                    & + .checkbox:before {
                        content: '\e835';
                        color: $nearblack;
                    }

                    &:checked + .checkbox:before {
                        content: '\e834';
                    }

                    &:active:not(:disabled) + .checkbox:before {
                        transform: scale3d(0.88, 0.88, 1);
                    }

                    &:disabled + .checkbox:before {
                        color: $darkergray !important;
                    }
                }
            }

            & label {
                position: relative;
                color: $black;
                cursor: pointer;
                pointer-events: all;

                & a {
                    color: $pink;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            & .label {
                position: relative;
                top: auto;
                left: auto;
                display: block;
                padding-left: 30px;
                font-family: "freight-text-pro", Arial, sans-serif;
                font-size: 19px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                color: $black;
                cursor: pointer;
                pointer-events: all;

                & a {
                    color: $pink;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &:not(.checkbox-rotate) {
                & .checkbox-default {
                    & input[type='checkbox']:checked + .checkbox:before {
                        color: $pink;
                    }
                }
            }
        }
    }
}
