/* ! Main src files*/

/* Vendors
   ------------------------------------------------------------------------- */

@import '../js/vendor/slick-carousel/slick/slick.scss';


/* Settings
   ------------------------------------------------------------------------- */

@import 'settings/base';
@import 'settings/font-families';
@import 'settings/font-settings';
@import 'settings/colors';
@import 'settings/helpers';

/* Mixins
   ------------------------------------------------------------------------- */
@import 'mixins/font-awesome';

/* Reset
   ------------------------------------------------------------------------- */

@import 'reset/normalize';
@import 'reset/box-sizing';

/* Components
   ------------------------------------------------------------------------- */
@import 'components/search-window';
@import 'components/header';
@import 'components/visual';
@import 'components/footer';
@import 'components/article';
@import 'components/share';
@import 'components/author';
@import 'components/content';
@import 'components/form';
@import 'components/related-items';
@import 'components/article-block';
@import 'components/podcast';
@import 'components/newsletter';
