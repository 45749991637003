.home-content,
.content {
    min-height: calc(100vh - 441px);

    & .container {
        height: 100%;
        padding: 44px 0 70px;

        & .paginate {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            & .paginate-prev,
            & .paginate-next {
                width: 54px;
                height: 54px;
                padding: 15px;
                font-size: 24px;
                color: $pink;
                border-radius: 4px;
                background-color: $black;
            }

            & .paginate-prev {
                margin-right: auto;
            }

            & .paginate-next {
                margin-left: auto;
            }
        }

        & .block-row {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }
    }
}

.plain-text {
    padding: 56px 0;
}

@media only screen and (max-width: 812px) {
    .home-content,
    .content {
        & .container {
            padding: 44px 16px 70px;
        }
    }

    .plain-text {
        width: calc(100% - 32px);
        margin: 0 auto;
    }
}

@media only screen and (max-width: 768px) {
    .home-content,
    .content {

        & .container {

            & .block-row {
                display: block;
            }
        }
    }
}
