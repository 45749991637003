header {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 100vw;
    padding: 28px 0;

    & .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & a.logo,
        & a.logo-menu {
            display: block;
            width: auto;
            height: 60px;
            
            & img {
                width: auto;
                height: 60px;
            }
        }

        & svg.menu {
            display: none;
        }
    
        & ul.nav,
        & .nav-mobile {
            display: inline-flex;
            width: auto;
            padding: 0;
            margin: 0;
            list-style: none;

            & li {
                margin: 0 10px 0 0;

                & a {
                    display: inline-block;
                    padding: 2px 8px 4px;
                    font-family: $heading;
                    font-size: 17px;
                    font-weight: $semibold;
                    line-height: 22px;
                    color: $white;
                    text-decoration: none;

                    &:hover,
                    &:active,
                    &.active {
                        background-color: $black;
                        border-radius: 4px;
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }
        }

        & .nav-mobile {
            display: none;
        }
    }
}

@media only screen and (max-width: 1024px) {
    header {
        & .container {
            & svg.menu {
                display: inline-block;
                width: 38px;
                height: 38px;
                margin-top: -4px;
                color: $white;
            }

            & ul.nav {
                display: none;
            }

            & .nav-mobile {
                position: fixed;
                top: 0;
                left: 0;
                display: block;
                width: 100vw;
                height: 100vh;
                background-color: $darkgray;
                transform: translateX(100vw);
                transition: all 300ms ease-in-out;

                & li {
                    & a {
                        position: relative;
                    }
                }

                &.show {
                    transform: translateX(0);
                }

                & a.logo-menu {
                    position: absolute;
                    top: 36px;
                    left: 0;
                }

                & svg.close {
                    position: absolute;
                    top: 36px;
                    right: 10px;
                    display: inline-block;
                    width: 38px;
                    height: 38px;
                    margin-top: -4px;
                    color: $white;
                }

                & ul {
                    position: relative;
                    list-style: none;
                    padding: 0;
                    margin: 152px 0 0;
                    
                    & li {
                        width: 100%;
                        margin-bottom: 32px;

                        & a {
                            width: 100%;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    header {
        & .container {
            & .nav-mobile {
                & a.logo-menu {
                    left: 16px;
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    header {
        padding: 16px 0;
        
        & .container {
            & a.logo {
                display: block;
                width: auto;
                height: 36px;

                & img {
                    width: auto;
                    height: 36px;
                }
            }

            & svg.menu {
                width: 28px;
                height: 28px;
            }

            & .nav-mobile {
                & a.logo-menu {
                    position: absolute;
                    top: 16px;
                    left: 16px;
                    display: block;
                    width: auto;
                    height: 36px;
                    
                    & img {
                        width: auto;
                        height: 36px;
                    }
                }

                & svg.close {
                    width: 28px;
                    height: 28px;
                    top: 16px;
                    right: 16px;
                }

                & ul {
                    margin: 94px 0 0;
                }
            }
        }
    }
}
