.article {
    width: 100%;
    max-width: 786px;
    margin: 0 auto;

    &-title {
        font-family: $heading;
        font-size: 7.5*$base-unit;
        line-height: 8.5*$base-unit;
        font-weight: $medium;
        font-style: $normal;
    }

    &-intro {
        max-width: 786px;
        margin: 7*$base-unit auto !important;
        font-family: $text;
        font-size: 3*$base-unit;
        line-height: 3.875*$base-unit;
        font-weight: $bold;
        font-style: $normal;
    }

    & p {
        margin: 0 0 4*$base-unit;
        font-family: $text;
        font-size: 3*$base-unit;
        line-height: 3.875*$base-unit;
        font-weight: $book;
        font-style: $normal;
    }

    ul,
    ol {
        margin: 0 0 4*$base-unit;

        li {
            font-family: $text;
            font-size: 3*$base-unit;
            line-height: 3.875*$base-unit;
            font-weight: $book;
            font-style: $normal;
        }
    }

    &-highlight {
        margin: 7*$base-unit 0;

        & span {
            color: $white;
            font-family: $text;
            font-size: 5*$base-unit;
            line-height: 7*$base-unit;
            font-weight: $semibold;
            font-style: $italic;
            background-color: $pink;
            box-shadow: 16px 0px 0px 0px $pink, -16px 0px 0px 0px $pink;
        }
    }

    & a {
        color: $pink;
    }
}

figure {
    width: 100%;
    max-width: none;
    margin: 56px 0;

    & img {
        width: 100%;
    }
}

.tingle-modal {
    figure {
        width: auto;
        max-width: none;
        margin: 0 0 30px 0;

        & img {
            width: auto;
        }
    }
}

figcaption {
    font-family: $text;
    font-size: 19px;
    font-weight: $blackfont;
    line-height: 25px;
    color: $middlegray;
}

.table {
    width: 100%;
    margin-bottom: 48px;
    border-collapse: collapse;

    a {
        color: #222;
        text-decoration: none;
    }

    tr td {
        padding: 16px 0;
        line-height: 24px;
        vertical-align: top;
        border-bottom: 1px solid #eee;
    }

    tr:last-child td {
        border-bottom: 0;

    }

    tr td:last-child {
        padding-left: 16px;
        text-align: right;
        font-size: 14px;
        color: #999;
    }
}

.accordion {
    padding: 48px 0;
    max-width: 786px;
    margin: 0 auto;
}

.accordion__item>* {
    display: none;
}

.accordion__item> :first-child {
    display: block;
}

.accordion__item> :first-child h3 {
    position: relative;
    cursor: pointer;
    margin: 0 0 16px;
    font-family: $heading;
    font-size: 18px;
    font-style: $normal;
    font-weight: $semibold;
    border-bottom: 1px solid #eee;
    line-height: 4*$base-unit;
    color: $black;
    text-decoration: none;

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 18px;
        height: 18px;
        font-family: 'Material Icons';
        font-size: 24px;
        font-style: normal;
        border: 0;
        border-spacing: 0;
        content: 'expand_more';
        font-weight: normal;
        line-height: 1;
        color: #FD6F7D;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        vertical-align: -6px;
        transition: all 0.2s ease;
        direction: ltr;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
    }
}

.accordion__item.open> :first-child h3 {
    border-bottom: 0;

    &:after {
        content: 'expand_less';
    }
}

.accordion__item.open>* {
    display: block;
}

.image-wide {
    width: 100vw;
    height: 500px;
    margin: 7*$base-unit 0 7*$base-unit calc(-50vw + 393px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.article-date {
    display: block;
    margin: 0 0 8px;
    font-family: $text;
    font-size: 16px;
    font-style: italic;
    font-weight: $book;
    line-height: 20px;
    color: $darkgray;
}

article.article>.article-date,
.article-intro>.article-date {
    display: block;
    margin: 16px 0;
    font-family: $text;
    font-size: 24px;
    font-style: italic;
    font-weight: $book;
    line-height: 31px;
    color: $darkgray;
}

article.article>.article-date {
    margin-top: 56px;
}

@media only screen and (min-width: 1281px) {
    .article {
        max-width: 786px;
    }

    .image-wide {
        margin: 7*$base-unit 0 7*$base-unit calc(-50vw + 393px);
    }
}

@media only screen and (max-width: 1280px) {
    .article {
        max-width: 786px;
    }

    .image-wide {
        margin: 7*$base-unit 0 7*$base-unit calc(-50vw + 393px);
    }
}

@media only screen and (max-width: 1024px) {
    .article {
        max-width: 786px;
    }

    .image-wide {
        margin: 7*$base-unit 0 7*$base-unit calc(-50vw + 393px);
    }
}

@media only screen and (max-width: 812px) {
    .article {
        max-width: 100%;
    }

    .image-wide {
        margin: 7*$base-unit 0 7*$base-unit -16px;
    }
}
